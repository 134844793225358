import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { logout, login, isLoggedIn, getUserOptions } from './AuthService'

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '0em',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2.5em',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1,25em',
      },
    },
  },
  logo: {
    textTransform: 'none',
    fontWeight: 600,
    color: 'white',
    marginRight: '10px',
    marginLeft: '25px',
    fontFamily: 'Montserrat',
  },
  logoContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tabContainer: {
    marginLeft: 'auto',
  },
  tab: {
    minWidth: 10,
    marginLeft: '25px',
  },
  button: {
    marginLeft: '25px',
    marginRight: '25px',
  },

  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

export default function Header() {
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [isLogout, setLogout] = useState(false)
    const userOptions = getUserOptions()

    const swag = () => {
       if(userOptions?.prefix && userOptions.prefix.length > 0){
           return true
       }
    }

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    const getDefaultRoute = () => {
        if (routes.length > 0) {
            return routes[0].link
        }
        return '/'
    }

    useEffect(() => {
        return () => {
            setLogout(0)
        }
    }, [isLogout])

    const routes = [
        ...(swag() ? [{ name: 'Insights', link: '/insights' }] : []),
        ...(userOptions.allow_fb_ads ? [{ name: 'FB Library', link: '/library' }] : []),
        ...(userOptions.allow_video ? [{ name: 'Instagram', link: '/instagram' },{ name: 'TikTok', link: '/tiktok' }] : [])
    ]

    useEffect(() => {
        const currentPath = window.location.pathname
        const routeIndex = routes.findIndex(route => route.link === currentPath)
        setValue(routeIndex >= 0 ? routeIndex : 0)
    }, [window.location.pathname, routes])

  const tabs = (
    <React.Fragment>
      <Tabs value={value} onChange={handleChange} className={classes.tabContainer}>
        {routes.map((route, index) => (
          <Tab
            key={`${route}${index}`}
            className={classes.tab}
            component={Link}
            to={route.link}
            label={route.name}
            aria-owns={route.ariaOwns}
            aria-haspopup={route.ariaPopup}
            onMouseOver={route.mouseOver}
          />
        ))}
      </Tabs>
      <IconButton
        title="Logout"
        onClick={() => {
          logout()
          setLogout(true)
        }}
        className={classes.button}
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <ExitToAppIcon />
      </IconButton>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {isLoggedIn() ? (
        <>
          <AppBar position="fixed" color="primary" className={classes.appbar}>
            <Toolbar disableGutters>
              <Button
                component={Link}
                to={getDefaultRoute()}
                disableRipple
                onClick={() => setValue(0)}
                className={classes.logoContainer}
              >
                <Typography className={classes.logo} variant="h4">
                  Dashboard
                </Typography>
              </Button>
              {tabs}
            </Toolbar>
          </AppBar>
          <div className={classes.toolbarMargin} />
        </>
      ) : (
        login()
      )}
    </React.Fragment>
  )
}
