import React from 'react'
import { MessageBar, DetailsList, DetailsListLayoutMode, SelectionMode, MarqueeSelection } from 'office-ui-fabric-react'

class ResultsPanel extends React.Component {
  componentDidMount() {
    this.props.store.dispatch({ type: 'resultPanelInit' })
  }

  render() {
    console.log(this.props.state.items)
    return (
      <div className="results-panel" style={{ padding: '0px 32px' }}>
        {this.props.state.messageBar && (
          <div style={{ position: 'fixed', top: '70px', right: '32px', zIndex: 100000 }}>
            <MessageBar
              style={{ margin: '10px' }}
              messageBarType={this.props.state.messageBar.type}
              isMultiline={true}
              onDismiss={() => {
                this.props.store.dispatch({ type: 'closeMessageBar' })
              }}
              dismissButtonAriaLabel="Close"
            >
              {this.props.state.messageBar.message}
            </MessageBar>
          </div>
        )}
        <MarqueeSelection selection={this.props.state.selection}>
          <DetailsList
            items={this.props.state.items}
            columns={this.props.state.columns}
            selectionMode={SelectionMode.multiple}
            layoutMode={DetailsListLayoutMode.justified}
            selection={this.props.state.selection}
            selectionPreservedOnEmptyClick={true}
          />
        </MarqueeSelection>
      </div>
    )
  }
}

export default ResultsPanel
