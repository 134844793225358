import * as xlsx from 'xlsx';
import React from 'react'
import { getFbLib } from '../Api'
import store from './Store'
import downloadFile from 'js-file-download'
import { Selection, Image, ImageFit, Link, TooltipHost } from 'office-ui-fabric-react'
import userSettings from '../utils/UserSettings'

const numberFormat = new Intl.NumberFormat('en-US')

const _columns = [
  {
    name: 'Page name',
    fieldName: 'pageName',
    key: 'pageName',
    minWidth: 150,
    maxWidth: 170,
    data: 'string',
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => {
      return (
        <TooltipHost content={'Page name'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          {/* <Link href={item.pageLink} target="_blank" rel="noopener noreferrer"> */}
            <div style={{ fontSize: '1.0em', fontWeight: '500', padding: '8px 0px', lineHeight: 1.8 }}>
              {item.pageName}
            </div>
          {/* </Link> */}
        </TooltipHost>
      )
    },
  },
  {
    name: 'Domain',
    fieldName: 'domain',
    key: 'domain',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    onRender: (item) => {
      const parsedUrl = getDomainFromUrl(item.promotedUrl); 
      return (
        <TooltipHost content={'Domain'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <Link href={parsedUrl.origin} target="_blank" rel="noopener noreferrer">
            <div style={{ fontSize: '1.0em', fontWeight: '500', padding: '8px 0px', lineHeight: 1.8 }}>
              {parsedUrl.hostname}
            </div>
          </Link>
        </TooltipHost>
      )
    }
  },

  {
    name: 'Company name',
    fieldName: 'companyName',
    key: 'companyName',
    minWidth: 180,
    maxWidth: 200,
    data: 'string',
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => {
      return (
        <TooltipHost content={'Company name'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '500', padding: '8px 0px', lineHeight: 1.8 }}>
            <p>{item.companyName}</p>
          </div>
        </TooltipHost>
      )
    },
  },

  {
    name: 'Duplicates',
    fieldName: 'duplicates',
    key: 'duplicates',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => {
      return (
        <TooltipHost content={'Duplicates'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8, margin: 'auto' }}>
            {numberFormat.format(Math.round(item.duplicates))}
          </div>
        </TooltipHost>
      )
    },
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
    isSorted: true,
    isSortedDescending: true,
    getKey: (item) => item.duplicates,
  },

  {
    name: 'Created At',
    fieldName: 'createdAt',
    key: 'createdAt',
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => {
      const dateObj = new Date(item.createdAt);

      const formattedDate = dateObj.toISOString().split('T')[0];

      const formattedTime = dateObj.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      return (
        <TooltipHost content={'Created At'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8 }}>
            {formattedDateTime}
          </div>
        </TooltipHost>
      )
    },
  },

  {
    name: 'Image',
    fieldName: 'creativeUrl',
    key: 'creativeUrl',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
      return renderImageCell(item.creativeUrl)
    },
  },

  {
    name: 'Text',
    fieldName: 'text',
    key: 'text',
    minWidth: 120,
    maxWidth: 250,
    isResizable: true,
    isMultiline: true,
    data: 'string',
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => (
      <TooltipHost content={'Text'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
        <div style={{ fontSize: '1.0em', fontWeight: '400', padding: '8px 0px', lineHeight: 1.8 }}>
          {item.text}
        </div>
      </TooltipHost>
    ),
  },

  {
    name: 'Url',
    fieldName: 'promotedUrl',
    key: 'promotedUrl',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    data: 'string',
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => (
      <TooltipHost content={'Promoted Url'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
        <Link href={item.promotedUrl} target="_blank" rel="noopener noreferrer">
          <div style={{ fontSize: '1.0em', fontWeight: '400', padding: '8px 0px', lineHeight: 1.8 }}>
            {item.promotedUrl}
          </div>
        </Link>
      </TooltipHost>
    ),
  }
]

function getDomainFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return {
      origin: parsedUrl.origin,
      hostname: parsedUrl.hostname
    };
  } catch (error) {
    console.error('Invalid URL:', url);
    return url; 
  }
}

function triggerSearch(filters) {
  return getFbLib(filters)
    .then((res) => store.dispatch({ type: 'gotSearchResults', res }))
    .catch((err) => store.dispatch({ type: 'searchError', err }))
}

function _onColumnClick(column, columns) {
  const newColumns = columns.slice()
  const currColumn = newColumns.filter((currCol) => column.key === currCol.key)[0]
  newColumns.forEach((newCol) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = true //!currColumn.isSortedDescending
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  return { columns: newColumns, sortField: currColumn.key, isSortAsc: !currColumn.isSortedDescending }
}

function extractImageNameFromUrl(url) {
  const UNKNOWN = 'unknown.jpg'
  let end = url.indexOf('?')
  if (end === -1) {
    end = url.length
  }
  const begin = url.lastIndexOf('/', end - 1)
  if (begin === -1) {
    return UNKNOWN
  } else {
    let u = url.substring(begin + 1, end)
    if (!u) {
      return UNKNOWN
    } else if (!u.match(/.*\.jpg/i) && !u.match(/.*\.png/i)) {
      return u + '.jpg'
    } else {
      return u
    }
    // return url.substring(begin + 1, end)
  }
}

function download(url) {
  const downloadOne = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((bl) => {
        downloadFile(bl, extractImageNameFromUrl(url))
      })
      .catch((err) => {
        console.log("Can't download file" + err.message)
      })
  }
  if (Array.isArray(url)) {
    url.forEach(downloadOne)
  } else {
    downloadOne(url)
  }
}

function renderImageCell(item) {
  const displayImage = (url) => {
    if (url.includes('.png') || url.includes('.gif') || url.includes('.jpg') || url.includes('.jpeg')) {
      return (
        <div key={url} className="image-preview">
          <a href={url} rel="noopener noreferrer" target="_blank">
            <Image src={url} alt={item.name} imageFit={ImageFit.contain} height="150px" onClick={() => download(url)} />
          </a>
        </div>
      )
    } else {
      return (
        <div key={url} className="image-preview">
          <Image src={'images/no-image.png'} imageFit={ImageFit.contain} height="150px" />
        </div>
      )
    }
  }
  return displayImage(item)
}

const DefaultResultsState = {
  info: 'Choose filtering options and press Search',
  progress: {},
  items: [],
  selection: new Selection({
    onSelectionChanged: () => {
      store.dispatch(({ type: 'onAdSelect' }));
    },
  }),
  loading: false,
  columns: _columns,
  sortField: 'duplicates',
  isSortAsc: false,
  atMetrics: {},
  lastFilters: {},
  createButtonPlatform: userSettings.getUserSettings().createButtonPlatform,
  messageBar: false,
}

export function resultsReducer(state = DefaultResultsState, event) {
  switch (event.type) {
    case 'resultPanelInit': {
      return state
    }
    case 'triggerSearch':
      triggerSearch(event.filters)
      setTimeout(() => state.selection.setAllSelected(false), 20);
      return { ...state, info: 'Getting filtered ads.', loading: true, items: [], lastFilters: event.filters }
    case 'gotSearchResults':
      if (event.res.error) {
        return { ...state, loading: false, info: event.res.error }
      } else {
        const info = event.res.length >= 100 ? 'Found 100+ ads.' : `Found ${event.res.length} ads.`
        return {
          ...state,
          loading: false,
          info,
          items: event.res,
        }
      }
    case 'searchError':
      console.log(event.err.stack)
      return { ...state, loading: false, info: event.err.message }
    case 'onColumnClick': {
      const { columns, sortField, isSortAsc } = _onColumnClick(event.item, state.columns)
      const lastFilters = { ...state.lastFilters, sortField, isSortAsc }
      setTimeout(() => store.dispatch({ type: 'triggerSearch', filters: lastFilters }), 20)
      return { ...state, columns, lastFilters }
    }
    case 'onChangeCreatePlatform': {
      userSettings.setUserSettings({ createButtonPlatform: event.createButtonPlatform })
      return { ...state, items: [...state.items], createButtonPlatform: event.createButtonPlatform }
    }
    case 'createCampaign': {
      const selectedAdIds = state.selection.getSelection().map(el => el._id)
      const link =
        `https://spm.shinez.io?` +
        `createFromFbLibrary` +
        `&id=${selectedAdIds.join(',')}`
      window.open(link, '_blank')

      setTimeout(() => state.selection.setAllSelected(false), 20);

      return state
    }
      case 'copyLinkToClipboard': {
          const selectedAdIds = state.selection.getSelection().map(el => el.creativeUrl)
          let links = selectedAdIds.join('\n')
          navigator.clipboard.writeText(links).then(() => {
              alert('Copied to clipboard!');
          });

          return state
      }

      case 'saveToFile': {
          const items = state.items
          const data = [];
          items.forEach((item, i) =>  {
              data.push(
                  {
                      "Page name": item.pageName,
                      "Domain": item.promotedUrl,
                      "Company name": item.companyName,
                      "Duplicates": item.duplicates,
                     "Created at": item.createdAt,
                     "Image url": item.creativeUrl,
                      "Text": item.text,

                  }
              )
          });

          let worksheet = xlsx.utils.json_to_sheet(data);
          worksheet['!cols'] = [
              { wch: 20 },
              { wch: 50 },
              { wch: 20 },
              { wch: 10 },
              { wch: 30 },
              { wch: 100 },
              { wch: 100 },
          ];

          let workbook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          xlsx.writeFile(workbook, 'fbLibrary.xlsx');
          return state
      }

    case 'openDialog': {
      return { ...state, showDialog: true, item: event.item }
    }
    case 'closeDialog': {
      return { ...state, showDialog: false }
    }
    case 'onAdSelect': {
      console.log('Item selected', state.selection.getSelection())
      return state
    }
    case 'closeMessageBar':
      return { ...state, messageBar: false }

    default:
      return state
  }
}
