import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import HttpsRedirect from 'react-https-redirect'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Insights from './Insights/Insights'
import FbAdLibrary from './ FbAdLibrary/FbAdLibrary'
import Instagram from './Instagram/Instagram'
import Tiktok from './TikTok/Tiktok'
import Header from './Header'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { isLoggedIn, requireAuth } from './AuthService'
import Login from './Login'
import Callback from './Callback'
import theme from './Theme'

const Root = () => {
    const getDefaultRoute = () => {
        const userOptions = JSON.parse(localStorage.getItem('user_options') || '{}').app_metadata || {}

        if ( userOptions?.prefix?.length > 0) return '/insights'
        if (userOptions?.allow_fb_ads) return '/library'
        if (userOptions?.allow_video) return '/instagram'
        return '/'
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <HttpsRedirect>
                    <BrowserRouter onEnter={requireAuth}>
                        <div>
                            {isLoggedIn() ? (
                                <>
                                    <Header />
                                    <Switch>
                                        <Route path="/insights" component={Insights} />
                                        <Route path="/library" component={FbAdLibrary} />
                                        <Route path="/instagram" component={Instagram} />
                                        <Route path="/tiktok" component={Tiktok} />
                                        <Redirect exact from="/" to={getDefaultRoute()} />
                                    </Switch>
                                </>
                            ) : (
                                <Switch>
                                    <Route exact path="/" component={Login} />
                                    <Route exact path="/insights" component={Login} />
                                    <Route exact path="/library" component={Login} />
                                    <Route exact path="/instagram" component={Login} />
                                    <Route exact path="/tiktok" component={Login} />
                                </Switch>
                            )}
                            <Route exact path="/callback" component={Callback} />
                        </div>
                    </BrowserRouter>
                </HttpsRedirect>
            </ThemeProvider>
        </div>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'))
