import React from 'react'
import { initializeIcons } from '@uifabric/icons'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react'
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator'
import { Separator } from 'office-ui-fabric-react/lib/Separator'
import store from './Store'
import ResultsPanel from './ResultsPanel'
import SearchPanel from './SearchPanel'

class Tiktok extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.getState()
    initializeIcons()
    store.subscribe(() => this.setState(store.getState()))
  }

  render() {
    return (
      <div>
        <Separator />
        <SearchPanel state={this.state.filters} store={store} />
        {this.state.results.loading ? <ProgressIndicator /> : <Separator />}
        <label style={{ padding: '0px 32px' }}>
          <b>{this.state.results.info}</b>
        </label>
        <div className="search-result">
          {this.state.results.loading && <Spinner label="loading..." size={SpinnerSize.large} />}
          {this.state.results.items.length > 0 ? <ResultsPanel state={this.state.results} store={store} /> : null}
        </div>
      </div>
    )
  }
}

export default Tiktok
