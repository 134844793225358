import { getAccessToken } from './AuthService'
import ky from 'ky'

export function filterAds(payload) {
  return ky
    .post('/api/insights', {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      json: payload,
      timeout: 360 * 1000,
    })
    .json()
}

export function getFbLib(payload) {
  return ky
    .post('/api/fblibrary', {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      json: payload,
      timeout: 360 * 1000,
    })
    .json()
}
export function getInstaReel(payload) {
  return ky
    .post('api/ig/reel', {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      json: payload,
      timeout: 360 * 1000,
    })
    .json()
}

export function geTiktokReel(payload) {
    return ky
        .post('api/tt/reel', {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
            json: payload,
            timeout: 360 * 1000,
        })
        .json()
}


export function getATData(name, start, end) {
  return ky
    .get('/api/insights/atdata', {
      searchParams: {
        campaign_name: name,
        start_date: start,
        end_date: end,
      },
      timeout: 30 * 1000,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    .json()
    .catch((err) => {
      console.log('error occurred' + err.stack)
      return { data: [] }
    })
}

export function getAnalysts() {
  const url = '/api/analysts'
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then((res) => {
    return res.json()
  })
}

export function getAnalystCode(name) {
  return ky
    .get('/api/analystCode', {
      searchParams: {
        name: name,
      },
      timeout: 360 * 1000,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    .json()
    .catch((err) => {
      console.log('error occurred' + err.stack)
      return { data: [] }
    })
}

export function downloadVideo(url, filename) {
    return fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const a = document.createElement('a');
            const blobUrl = window.URL.createObjectURL(blob);
            a.href = blobUrl;
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        });
}