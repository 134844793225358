import React from 'react'
import { geTiktokReel } from '../Api'
import store from './Store'
import { Selection, Image, ImageFit, Link, TooltipHost } from 'office-ui-fabric-react'
import userSettings from '../utils/UserSettings'

const numberFormat = new Intl.NumberFormat('en-US')

const _columns = [
  {
    name: 'Account',
    fieldName: 'account',
    key: 'account',
    minWidth: 150,
    maxWidth: 170,
    data: 'string',
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => {
      return (
        <TooltipHost content={'Account'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '500', padding: '8px 0px', lineHeight: 1.8 }}>
            {item.account}
          </div>
        </TooltipHost>
      )
    },
  },
  {
    name: 'Video content',
    fieldName: 'video',
    key: 'video',
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
      return renderImageCell(item.image)
    },
  },
  {
    name: 'Video link',
    fieldName: 'video',
    key: 'videoLink',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    onRender: (item) => {
      const parsedUrl = item.link
      return (
        <TooltipHost content={'video'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <Link href={parsedUrl} target="_blank" rel="noopener noreferrer">
            <div style={{ fontSize: '1.0em', fontWeight: '500', padding: '8px 0px', lineHeight: 1.8 }}>{parsedUrl}</div>
          </Link>
        </TooltipHost>
      )
    },
  },

  {
    name: 'Reel ID',
    fieldName: 'reelId',
    key: 'reelId',
    minWidth: 180,
    maxWidth: 200,
    data: 'string',
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => {
      return (
        <TooltipHost
          content={'Company name'}
          calloutProps={{ gapSpace: 0 }}
          styles={{ root: { display: 'inline-block' } }}
        >
          <div style={{ fontSize: '1.0em', fontWeight: '500', padding: '8px 0px', lineHeight: 1.8 }}>
            <p>{item.reelId}</p>
          </div>
        </TooltipHost>
      )
    },
  },
    {
        name: 'Is hashtag',
        fieldName: 'isHashtag',
        key: 'isHashtag',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item) => {
            return (
                <TooltipHost content={'Views'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                    <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8, margin: 'auto' }}>
                        {JSON.stringify(item.isHashtag)}
                    </div>
                </TooltipHost>
            )
        },
        onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
        isSorted: true,
        isSortedDescending: true,
        getKey: (item) => item.views,
    },
  {
    name: 'Comments',
    fieldName: 'comments',
    key: 'comments',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => {
      return (
        <TooltipHost content={'Comments'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8, margin: 'auto' }}>
            {numberFormat.format(Math.round(item.comments))}
          </div>
        </TooltipHost>
      )
    },
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
    isSorted: true,
    isSortedDescending: true,
    getKey: (item) => item.comments,
  },
  {
    name: 'Likes',
    fieldName: 'likes',
    key: 'likes',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => {
      return (
        <TooltipHost content={'Likes'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8, margin: 'auto' }}>
            {numberFormat.format(Math.round(item.likes))}
          </div>
        </TooltipHost>
      )
    },
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
    isSorted: true,
    isSortedDescending: true,
    getKey: (item) => item.likes,
  },
  {
    name: 'Views',
    fieldName: 'comments',
    key: 'views',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => {
      return (
        <TooltipHost content={'Views'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
          <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8, margin: 'auto' }}>
            {numberFormat.format(Math.round(item.views))}
          </div>
        </TooltipHost>
      )
    },
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
    isSorted: true,
    isSortedDescending: true,
    getKey: (item) => item.views,
  },
  {
    name: 'Created At',
    fieldName: 'created_at',
    key: 'created_at',
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),

    onRender: (item) => {
      const dateStr = item.created_at.$date || item.created_at
      const dateObj = new Date(dateStr)

      const formattedDate = dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })

      const formattedTime = dateObj.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })

      const formattedDateTime = `${formattedDate} ${formattedTime}`

      return (
        <TooltipHost
          content={'Created At'}
          calloutProps={{ gapSpace: 0 }}
          styles={{ root: { display: 'inline-block' } }}
        >
          <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8 }}>
            {formattedDateTime}
          </div>
        </TooltipHost>
      )
    },
  },
]

function triggerSearch() {
  return geTiktokReel()
    .then((res) => store.dispatch({ type: 'gotSearchResults', res }))
    .catch((err) => store.dispatch({ type: 'searchError', err }))
}

function _onColumnClick(column, columns) {
  const newColumns = columns.slice()
  const currColumn = newColumns.filter((currCol) => column.key === currCol.key)[0]
  newColumns.forEach((newCol) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = true
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  return { columns: newColumns, sortField: currColumn.key, isSortAsc: !currColumn.isSortedDescending }
}

function renderImageCell(item) {
    const [hasError, setHasError] = React.useState(false);

    const displayMedia = (url) => {
        try {
            if (url && typeof url === 'string' && !hasError) {
                return (
                    <div key={url} className="image-preview">
                        <Image
                            src={url}
                            imageFit={ImageFit.contain}
                            height="250px"
                            width="100%"
                            onLoadingStateChange={(loadState) => {
                                if (loadState === 3) {
                                    setHasError(true);
                                }
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="image-preview">
                        <Image src={'images/no-image.png'} imageFit={ImageFit.contain} height="150px" />
                    </div>
                );
            }
        } catch (error) {
            console.error('Error displaying image:', error);
            return (
                <div className="image-preview">
                    <Image src={'images/no-image.png'} imageFit={ImageFit.contain} height="150px" />
                </div>
            );
        }
    };
    return displayMedia(item);
}

const DefaultResultsState = {
  info: 'Choose filtering options and press Search',
  progress: {},
  items: [],
  selection: new Selection({
    onSelectionChanged: () => {
      store.dispatch({ type: 'onAdSelect' })
    },
  }),
  loading: false,
  columns: _columns,
  sortField: 'duplicates',
  isSortAsc: false,
  atMetrics: {},
  lastFilters: {},
  createButtonPlatform: userSettings.getUserSettings().createButtonPlatform,
  messageBar: false,
}

export function resultsReducer(state = DefaultResultsState, event) {
  switch (event.type) {
    case 'resultPanelInit': {
      return state
    }
    case 'triggerSearch':
      triggerSearch()
      setTimeout(() => state.selection.setAllSelected(false), 20)
      return { ...state, info: 'Getting videos...', loading: true, items: [], lastFilters: event.filters }
    case 'gotSearchResults':
      if (event.res.error) {
        return { ...state, loading: false, info: event.res.error }
      } else {
        const info = event.res.length >= 100 ? 'Found 100+ videos.' : `Found ${event.res.length} videos.`
          console.log(`Found ${event.res.length} videos.`)
          console.log(event.res)
        return {
          ...state,
          loading: false,
          info,
          items: event.res,
        }
      }
    case 'searchError':
      console.log(event.err.stack)
      return { ...state, loading: false, info: event.err.message }
    case 'onColumnClick': {
      const { columns, sortField, isSortAsc } = _onColumnClick(event.item, state.columns)
      const lastFilters = { ...state.lastFilters, sortField, isSortAsc }
      setTimeout(() => store.dispatch({ type: 'triggerSearch', filters: lastFilters }), 20)
      return { ...state, columns, lastFilters }
    }

    case 'copyLinkToClipboard': {
      const selectedAdIds = state.selection.getSelection().map((el) => el.video)
      let links = selectedAdIds.join('\n')
      navigator.clipboard.writeText(links).then(() => {
        alert('Copied to clipboard!')
      })

      return state
    }

    case 'openDialog': {
      return { ...state, showDialog: true, item: event.item }
    }
    case 'closeDialog': {
      return { ...state, showDialog: false }
    }
    case 'onAdSelect': {
      console.log('Item selected', state.selection.getSelection())
      return state
    }
    case 'closeMessageBar':
      return { ...state, messageBar: false }

    default:
      return state
  }
}
